$darkGrey: #4e4e4e;
$lightGrey: #787878;
$white: #FFFFFF;
$black: #000000;
@import 'mixins';
@media screen and (max-width: 320px) {
  body.mobileBrowser{
    #viewerLogin{
      #loginForm {
        box-sizing: border-box;
        position: absolute;
        z-index: 101;
        top: 50%;
        left: 0;
        margin-left: 0;
        margin-top: -55px;
        padding: 5px;
        width: 100%;
        #loginFields {
          width: 100%;
        }
        #loginButtons {
          width: 100%;
        }
      }
    }
    input.span4{
      width: 270px;
    }
    #article{
      .panel{
        .buttons{
          width: 100%;
          padding-left: 55px;
        }
        #articleClose{
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
    #mobileArticle{
      position: relative;
      left: 0;
      top: 0;
    }
    #mobileMenu{
      position: relative;
      left: 0;
      top: 0;
    }
  }
}
/* Hack for android keyboard */
@media screen and (max-height: 360px){
  #menuOptions{
    display: none;
  }
}


/* Apple devices */
body.deviceApple{
  #article{
    #contentWrapper {
      -webkit-overflow-scrolling: touch;
      overflow: auto;
      #textViewContent {
        overflow: visible;
        /*height: auto; */
      }
    }
  }
}

@media (orientation:landscape) {
  html > body.ios7 {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 672px !important;
  }
}

/* Mobile Styles */
body.mobileBrowser{
  #LeftMenu{
    width: 61px;
    left: 0;
    &.opened{
      width: 239px;
      left: 0;
    }
    &.closed{
      width: 61px;
      left: -61px;
    }
  }
}

body.mobileBrowser #article{
  #contentWrapper {
    padding: 0;
  }
}

body.mobileBrowser #Content{
  box-sizing: border-box;
}
body.mobileBrowser #Panel{
  top: 0 !important;
  left: 0;
  margin-left: 0;
  width: 100%;
  position: static;
  padding: 0;
  height: 40px;
  display: block;
  z-index: 3;
  #centerButtons{
    display: table;
    margin: 0 auto;
    width: 160px;
  }
  button{
    @include border-radius(0);
    z-index: 1;
    padding: 7px;
  }
  .taskbar_btn{
    width: 26px;
    height: 26px;
    display: block;
    background-size: 100% auto;
  }
}


body.mobileBrowser #View{
  width:100% !important;
  height: 100% !important;
  left: 0;
  background-color: $darkGrey;
}

body.mobileBrowser #mobileMenu{
  position: absolute;
  padding: 0;
  width: 40px;
  height: 40px;
  z-index: 2;
}

body.mobileBrowser #mobileArticle{
  position: absolute;
  left: 0px;
  top: 0px;
}

body.mobileBrowser #article{
  height: 100%;
  top: 0;
  &.extended{
    width: 100%;
    z-index: 1;
  }
  button {
    z-index: 3;
    margin: 0;
    border:0;
    background:none;
    display: inline-block;
  }
  .panel{
    position: relative;
    width: 100%;
    max-width: 100%;
    top: 0;
    background-color: $black;
    .buttons{
      width: 175px;
      padding-left: 55px;
      padding-right: 55px;
    }
    #articleClose{
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

body.mobileBrowser .loader{
  padding-left: 0;
}

body.mobileBrowser #mediaContainer{
  background-color: rgba(250,250,250, 1);
  position: absolute;
  z-index: 100;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
}
body.mobileBrowser #mediaPanel{
  display: block;
  height: 35px;
}
body.mobileBrowser #mediaVideo{
  margin: 0 auto;
}
body.mobileBrowser #mediaAudio{
  margin: 0 auto;
}