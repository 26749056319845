$darkGrey: #4e4e4e;
$lightGrey: #787878;
$white: #FFFFFF;
$black: #000000;
@import 'mixins';

body, html {
  height: 100%;
  width: 100%;
  background-color: $darkGrey;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -ms-touch-action: none;
  -moz-user-select: -moz-none;
  overflow: hidden;
  position: absolute;
}

#zineViewer {
  position: absolute;
  width: 100%;
  height: 100%;
}

#cmsProxyFrame {
  visibility: hidden;
  display: none;
}

#LeftMenu {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  overflow: visible;
  width: 61px;
  z-index: 2;
  &.opened {
    width: 239px;
    z-index: 4;
  }
  &.extended {
    width: 591px;
    z-index: 4;
  }
  .Menu {
    width: 60px;
    .active {
      position: relative;
      z-index: 5;
      width: 60px;
    }
    &.opened {
      left: 178px;
    }
    &.extended {
      left: 530px;
    }
  }
  #extend {
    display: none;
    position: absolute;
    right: -13px;
    top: 10px;
    z-index: 5;
    width: 13px;
    height: 26px;
    background-size: 100% auto;
    padding: 0;
    margin: 0;
  }
  &.issuesMenu {
    #extend {
      display: block;
    }
  }
  &.searchMenu {
    #extend {
      display: block;
    }
  }
  &.contentsMenu {
    #extend {
      display: block;
    }
  }
  &.thumbnailsMenu {
    #extend {
      display: block;
    }
  }
  i {
    background-size: 100% auto;
  }
}

#menuData {
  position: absolute;
  left: 0;
  width: 178px;
  height: 100% !important;
  overflow: hidden;
  &.extended {
    width: 530px;
  }
}

#menuIssue {
  position: absolute;
  top: 166px;
}

#menuCMS {
  position: absolute;
  top: 51px;
}

#menuOptions {
  position: absolute;
  bottom: 1%;
}

/*.closed {*/
/*left: 5px; }*/

#scrollWrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 197px;
  height: 100%;
}

#scrollWrapper.extended {
  width: 547px;
}

#scrollWrapper::-webkit-scrollbar {
  width: 17px;
  background-color: #000;
  color: #000;
}

.header {
  padding: 5px 10px;
  font-size: 13pt;
  font-style: normal;
  text-align: center;
}

#contentsData {
  height: 100%;
  .header {
    margin-bottom: 5px;
  }
  ul {
    position: relative;
    li {
      display: block;
      position: relative;
      overflow: visible;
      span {
        cursor: pointer;
        width: 100%;
        display: block;
        max-height: 36px;
        margin-bottom: 10px;
        overflow: hidden;
        line-height: 18px;
        font-size: 12px;
        &.folder {
          position: absolute;
          display: block;
          right: -12px;
          top: 3px;
        }
      }
    }
  }
}

#thumbnailsData {
  height: 100%;
  .header {
    margin-bottom: 5px;
  }
  ul {
    background-color: #080808;
    padding-left: 4px;
    li {
//      background-color: #080808;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      width: 82.5px;
      margin-right: 5px;
      margin-bottom: 5px;
      height: 110px;
      float: left;
      img {
        position: absolute;
        cursor: pointer;
        width: 100%;
        height: 100%;
      }
    }
  }
}

#issuesData {
  height: 100%;
}

#issuesList {
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  display: block !important;
  padding: 5px 0 0 4px;
  height: 100%;

  .issue {
    margin-right: 5px;
    margin-bottom: 5px;
    display: block !important;
    width: 170px;
    height: 280px;
    float: left;
    cursor: pointer;

    .issueImageContainer{
      position: relative;
      margin-bottom: 5px;
    }
  }
  .imageOverlay {
    position: absolute;
    z-index: 1;
  }
  span {
    width: 100%;
    display: inline-block;
    text-align: center;
    font-weight: 700;
    color: white;
  }
}

#searchData {
  height: 100%;
  padding: 0 4px;
  .searchResult {
    .searchImage {
      width: 60px;
      height: 60px;
      float: left;
      margin: 0 5px 5px 0;
    }
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 10px 5px 0 5px;
      top: -10px;
      left: -5px;
      z-index: 1;
      display: none;
    }
  }
}

#searchList .searchResult {
  margin-top: 10px;
  position: relative;
  &:hover .overlay {
    display: block;
  }
  .title {
    float: left;
    width: 105px;
    font-size: 9pt;
    line-height: 12pt;
    font-weight: bold;
    margin-bottom: 5px;
  }
  img {
    float: left;
    margin: 0 5px 5px 0;
  }
}

#searchData.extended #searchList .searchResult .title {
  width: 455px;
}

#searchList .searchResult .context {
  font-size: 9pt;
  line-height: 12pt;
}

#searchList .searchResult .divider {
  clear: both;
  border-bottom: 1px solid grey;
}

#searchData .searchResult:hover {
  cursor: pointer;
}

#searchData .searchGroupInfo:hover {
  cursor: pointer;
}

#searchData .searchResultIssueTitle {
  width: 100%;
  display: inline-block;
  font-size: 11pt;
  font-weight: 700;
  padding-bottom: 5px;
}

#searchData #searchLoading {
  margin: 10px 0;
  width: 100%;
}

#searchData .searchImage {
  position: relative;

}

#searchGroups {
  border-top: 1px solid grey;
  padding-top: 10px;
  margin-top: 10px;
}

#searchGroups .issueImage {
  position: relative;
  width: 60px;
  height: 85px;
  float: left;
}

#searchData .imageOverlay {
  position: absolute;
  z-index: 1;
}

#searchData .issueImageOverlay {
  /*height: 89px;*/
}

#menuData #searchNav {
  width: 170px;
}

#menuData.extended #searchNav {
  width: 520px;
}

#searchNav #searchInput {
  width: 155px;
  &:focus {
    border-color: black;
    box-shadow: none;
  }
}

#menuData.extended #searchNav #searchInput {
  width: 457px;
}

#menuData.extended #searchButton {
  margin-top: -10px;
}

#searchNav.extended #advancedSearchToggle {
  padding: 5px 0 0 0;
}

#searchNav #advancedSearchToggle {
  height: 25px;
  display: inline-block;
  margin-top: 0;
  padding: 5px 0 0 5px;
  cursor: pointer;
  text-decoration: underline;
}

#searchNav #advancedSearch {
  margin-top: 5px;
}

#searchNav #orderOptions input {
  margin: 0 5px 0 0;
}

#searchNav #searchInfo {
  margin-top: 10px;
  padding-top: 5px;
  display: inline-block;
  width: 100%;
  border-top: 1px solid grey;
  text-align: center;
}

#orderOptions .radioText {
  width: 100%;
  display: inline-block;
}

#menuData.extended #orderOptions .radioText {
  margin-left: 43px;
  width: initial;
}

#menuData.extended #advancedSearchToggle {
  display: none;
}

#menuData.extended #orderOptions {
  margin-bottom: 5px;
}

#menuData button.extend {
  position: absolute;
  left: 141px;
  top: 10px;
  border: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 0;
  z-index: 2;
}

#menuData button.extend.extended {
  left: 491px;
}

#searchGroups .divider {
  clear: both;
  margin-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid grey;
}

#searchGroups .searchGroupInfo {
  position: relative;
}

#searchGroups .searchGroupInfo:hover .overlay {
  width: 100%;
  padding: 6px 5px 5px 5px;
  top: -11px;
  left: -5px;
  background-color: #e6e6e6;
  height: 100%;
  position: absolute;
  opacity: 0.2;
}

#searchGroups .searchResult {
  position: relative;
}

#searchGroups .searchResult:hover .overlay {
  width: 100%;
  padding: 6px 5px 5px 5px;
  top: -11px;
  left: -5px;
  height: 100%;
  position: absolute;
}

#searchGroups .searchResult .title {
  float: left;
  width: 105px;
  font-size: 9pt;
  line-height: 12pt;
  font-weight: bold;
  margin-bottom: 5px;
}

#searchData.extended #searchGroups .searchResult .title {
  width: 455px;
}

#searchGroups .searchResult .context {
  font-size: 9pt;
  line-height: 12pt;
}

#searchList .searchResult .divider {
  clear: both;
  border-bottom: 1px solid grey;
}

#searchGroups .searchGroupInfo .title {
  display: inline-block;
  width: 160px;
  font-size: 12pt;
  font-weight: bold;
  padding-left: 10px;
}

#searchData.extended .searchGroupInfo .title {
  width: 500px;
}

#searchData {
  font-family: 'Open Sans', sans-serif;
}

#searchData .bold {
  font-weight: 900;
}

#menuData #topWrapper {
  display: none;
}

#menuData.searchClass #topWrapper {
  display: block;
}

#topWrapper {
  padding: 5px;
}

/* these control the display of the top nav */

#Panel {
  display: inline-block;
  visibility: hidden;
  height: 32px;
  padding: 3px 8px;
  position: absolute;
  top: 1%;
  left: 50%;
  //overflow: hidden;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  z-index: 3;
  margin-left: -200px;
  button {
    z-index: 3;
    padding: 7px;
    border: 0;
    /* padding: 0; */
    background: none;
    display: block;
    float: left;
    height: 40px;
  }
  div.slider {
    width: 83px !important;
    margin-left: 7px;
    margin-top: 10px;
    margin-right: 7px;
    float: left;
    display: block;
  }
  span {
    z-index: 3;
    margin: 7px;
    width: 95px;
    padding: 0;
    display: block;
    float: left;
    line-height: 28px;
    vertical-align: middle;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    text-align: center;
    max-height: 30px;
  }
}

/* classes for displaying the left and right navigation arrows */

.arrows_btn {
  display: inline-block;
  width: 26px !important;
  height: 26px !important;
  border: 0;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  z-index: 2;
}

#ArrowLeft {
  position: absolute;
  left: 5px;
  top: 50%;
  background-position: 0px 0px;
  visibility: hidden;
}

#ArrowLeft:hover, #ArrowLeft[disabled], #ArrowLeft.disabled, #ArrowLeft:active,
#ArrowRight:hover, #ArrowRight[disabled], #ArrowRight.disabled, #ArrowRight:active {
  background-color: transparent;
}

#ArrowRight {
  position: absolute;
  right: 5px;
  top: 50%;
  visibility: hidden;
}

#View {
  position: absolute;
  top: 0;
  left: 61px;
  right: 0;
  bottom: 0;
  height: auto;
  width: auto;
}

#View.fullscreen {
  left: 0;
  z-index: 3;
}

#Content {
  width: 100%;
  height: 100%;
  background-color: #fff;
  -webkit-borer-radius: 1px;
  -moz-border-radius: 1px;
  position: absolute;
  .mediaContent {
    position: absolute;
    z-index: 1;
    video, img {
      width: 100%;
      height: 100%;
    }
  }
}

.menu-button {
  width: 100%;
}

#blurBackground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  cursor: pointer;
}
#toggleDownload{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  cursor: pointer;
}


/* The default test which displays when no issue is selected is styled here */
#blurBackground .information {
  width: 400px;
  position: relative;
  top: 40%;
  left: 50%;
}

.slimScrollDiv {
  top: 1% !important;
}

.slimScrollRail {
  height: 99% !important;
}

#downloadIssue {
  position: fixed;
  z-index: 6;
  width: 95%;
  padding: 30px 30px 60px 30px;
  min-width: 270px;
  max-width: 1000px;
  background-color: rgba(250, 250, 250, 1);
  -moz-box-shadow: 0 0 5px #bbb;
  -webkit-box-shadow: 0 0 5px #bbb;
  box-shadow: 0px 0px 5px #bbb;
  box-sizing: border-box;
  visibility: hidden;
}

#downloadIssue .download-progress {
  min-width: 200px;
}

#downloadIssue .download-progress span {
  font-family: 'Open Sans', sans-serif;
}

#downloadIssue button {
  position: absolute;
  width: 20%;
  min-width: 86px;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

#startDownload {
  left: 30px;
  bottom: 30px;
}

#stopDownload {
  position: absolute;
  right: 30px;
  bottom: 30px;
}

#infoDownload {
  font-family: 'Open Sans', sans-serif;
  padding-bottom: 10px;
  display: inline-block;

  .warning {
    color: #faa732;
  }
}

span.closeDownload {
  position: absolute;
  right: 10px;
  top: 6px;
  cursor: pointer;
}

#messageDownload {
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
  position: absolute;
  padding-bottom: 6px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  left: 0;
  bottom: 30px;
  font-size: 14pt;

  &.info {
    color: #49afcd;
  }

  &.warning {
    color: #faa732;
  }

  &.error {
    color: #da4f49;
  }
}

#article {
  z-index: 5;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $darkGrey;
  &.extended {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    .panel {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
    }
  }
  .overlay {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.9);
    .shareCommentWindow {
      background-color: rgba(250, 250, 250, 1);
      -moz-box-shadow: 0 0 5px #bbb;
      -webkit-box-shadow: 0 0 5px #bbb;
      box-shadow: 0px 0px 5px #bbb;
      width: 50%;
      margin: 7% auto;
      textarea {
        width: 94%;
        margin: 2%;
        background-color: #f7f7f7;
        background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
        background-repeat: repeat-x;
        border-radius: 4px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
        overflow: hidden;
      }
      .form-actions {
        button {
          cursor: pointer;
          font-weight: bold;
        }
        .cancel {
          float: left;
        }
        .submit {
          float: right;
        }
        .option {
          float: left;
          padding-top: 5px;
          * {
            float: left;
            margin-left: 5px;
          }
        }
      }
    }
  }
  #contentWrapper {
    padding: 40px 90px;
    height: 100%;
    @include box-sizing(border-box);
    #textViewContent {
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
      height: 100%;
      border: 0;
      background-color: #ffffff;
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background-color: $darkGrey;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        background-color: $lightGrey;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        -webkit-box-shadow: inset 0 0 4px rgba(255, 255, 255, 0.8);
        background-color: $darkGrey;
      }
    }
  }
  .panel {
    background-color: transparent;
    width: 100%;
    height: 40px;
    position: absolute;
    top: 5px;
    text-align: center;
    .buttons {
      margin: 0 auto;
      background-color: black;
      height: 40px;
      display: inline-block;
      padding: 0 20px;
      button {
        padding: 7px;
        margin: 0;
        border: 0;
        display: block;
        float: left;
        background-color: transparent;
      }
      .btn-group {
        display: block;
        float: left;
      }
      .dropdown-menu {
        height: 40px;
        top: 40px;
        box-sizing: border-box;
        background-color: #cccccc;
        margin: 0 0 0 -44px;
        padding: 0;
        border: 0;
        width: 120px;
        min-width: 120px;
        @include border-radius(0);
        .article_font_small {

        }
      }
    }
  }
  .arrow_button_nav {
    width: 80px;
    height: 80px;
    padding: 0;
    border: 0;
    display: block;
  }

  .arrow_button {
    background-size: 100% auto;
    @extend .arrow_button_nav;
  }

  #previousArticle {
    position: absolute;
    top: 49%;
    left: 5px;
    @extend .arrow_button_nav;

  }
  #nextArticle {
    position: absolute;
    top: 49%;
    right: 5px;
    @extend .arrow_button_nav;
  }
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  opacity: 0.8;
  z-index: 2;
  .image {
    background: url("../img/loader-4e4e4e-ffffff.gif") no-repeat center center;
    height: 100%;
    width: 100%;
  }
  &.fullscreen {
    left: 0;
    z-index: 4;
  }
}

#customPage {
  position: absolute;
  top: 0;
  left: 125px;
  right: 0;
  height: 100%;
  display: none;
  border-left: 1px solid darkgray;
  background: url("../img/loader-mini-inverse.gif") no-repeat center center;

  .panel {
    background-color: #cccccc;
    height: 30px;
    padding: 4px 0;
    width: 100%;

    #pageClose {
      position: absolute;
      right: 5px;
      top: 4px;
    }
  }

  .content {
    background-color: #ffffff;
  }
}

#viewerLogin {
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(128, 128, 128, 0.6);

  #loginForm {
    position: absolute;
    z-index: 101;
    left: 50%;
    top: 50%;
    margin-left: -128px;
    margin-top: -55px;
    background-color: #ffffff;
    padding: 5px;
    width: 327px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

    #loginFields {
      width: 327px;

      #closeLogin {
        position: absolute;
        right: 3px;
        top: 2px;
        cursor: pointer;
        font-size: 17pt;
      }
    }

    #loginButtons {
      position: relative;
      width: 327px;
      text-align: right;

      #errorText {
        position: absolute;
        left: 5px;
        top: 5px;
        color: red;
      }

      .loading {
        display: inline-block;
        background: url("../img/loader-mini.gif") no-repeat center center;
      }
    }
  }
}

#ie8Warning {
  display: none;
  z-index: 101;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(128, 128, 128, 1);
}

#ie8Warning div {
  position: fixed;
  z-index: 101;
  left: 40%;
  top: 50%;
  margin-left: -128px;
  margin-top: -55px;
  background-color: #ffffff;
  padding: 50px 20px 50px 20px;
  width: 40%;
}

#alert {
  position: absolute;
  z-index: 10000;
  top: 0;
  right: 0;
  min-width: 200px;
  max-width: 800px;
  width: 30%;
}

#alert .alert {
  margin-bottom: 0 !important;
}

/*
 * Slider
 */
.slider {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  color: #000;
}

.slider.slider-horizontal {
  width: 210px;
  height: 20px;
}

.slider.slider-horizontal .slider-track {
  height: 1px;
  width: 100%;
  top: 50%;
  left: 0;
  border-width: 1px;
  border-style: solid;
  margin-top: -2px;
}

.slider.slider-horizontal .slider-selection {
  height: 100%;
  top: 0;
  bottom: 0;
}

.slider.slider-horizontal .slider-handle {
  margin-left: -7px;
  margin-top: -7px;
}

.slider.slider-horizontal .slider-handle.triangle {
  border-width: 0 10px 10px 10px;
  width: 0;
  height: 0;
  border-bottom-color: #000;
  margin-top: 0;
}

.slider input {
  display: none;
}

.slider .tooltip-inner {
  white-space: nowrap;
}

.slider-handle.round {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

.slider-handle {
  position: absolute;
  width: 15px;
  height: 15px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff000000', endColorstr='#ff000000', GradientType=0);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
  opacity: 0.8;
  border: 0 solid transparent;
}

.slider-selection {
  position: absolute;
  background-color: #000;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.slider-track {
  position: absolute;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.left_nav_button {
  width: 100%;
  border: 0;
}

#support {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 1000;
  background-color: #eeeeee;
  display: none;
}

#support p {
  display: inline-block;
  font-size: 18pt;
  position: relative;
}

#mediaContainer {
  position: absolute;
  z-index: 100;
  left: 45%;
  top: 45%;
}

#mediaPanel {
  display: none;
}

#mediaClose {
  margin: 0 2px;
  border: 0;
  background: none;
  display: inline-block;
  float: right;
}

button:focus {
  outline: none;
}